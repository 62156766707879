<template>
  <div>
    <div  class="main homeCard"  v-for="item in applicationList">

      <!-- <a-card class="card" @click="goto('/application')">
        <img  src="https://yyn-manager-1254950508.coscd.myqcloud.com/portal/icon_itinerary%402x.png"/>
        <a-card-meta title="应用访问管理">
        </a-card-meta>
      </a-card>
      
      <a-card class="card" @click="goto('/project')">
        <img  src="https://yyn-manager-1254950508.coscd.myqcloud.com/portal/icon_itinerary%402x.png"/>
        <a-card-meta title="项目管理">
        </a-card-meta>
      </a-card>

      <a-card class="card" @click="goto('/sj')">
        <img  src="https://yyn-manager-1254950508.coscd.myqcloud.com/portal/icon_itinerary%402x.png"/>
        <a-card-meta title="商机管理">
        </a-card-meta>
      </a-card>

      <a-card class="card" @click="goto('/gys')">
        <img  src="https://yyn-manager-1254950508.coscd.myqcloud.com/portal/icon_itinerary%402x.png"/>
        <a-card-meta title="供应商管理">
        </a-card-meta>
      </a-card> -->

      <a-card class="card" @click="goto(item.applicationUri)">
        <img  src="https://yyn-manager-1254950508.coscd.myqcloud.com/portal/icon_itinerary%402x.png"/>
        <!-- <a-card-meta :title="item.systemName">
        </a-card-meta> -->
        <div class="content">{{item.applicationName}}</div>
      </a-card>

    </div>


  </div>
</template>
<script>
import {getUserInfo } from '@/network/user/login'
import  config from '@/config/modeUrl'
export default {
  data(){
    return{
      applicationList:[
      ]
    }
  },
  created(){
      var tyApplicationList=this.$store.getters.userInfo.tyApplicationList;
      for(var i = 0; i < tyApplicationList.length; i++) {
        this.applicationList.push(tyApplicationList[i])
      }
      console.log(this.applicationList)
  }, 
  methods: {
    goto(path){
      console.log(path)
      // window.location.href = ' https://t-it.yntengyun.com/manage-web/'
      // window.location.href = 'http://192.168.15.67:8091/manage-web/'
      window.location.href = config.baseUrl.replace('/author/',path)

      // if(path.indexOf("project")!=-1||path.indexOf("shangji")!=-1){
      //   this.$notification.info({
      //     message: '提示',
      //     description: `项目暂未开放，敬请期待`,
      //     duration: 3
      //   })
      // }else{
      //   window.location.href = config.baseUrl.replace('/author/',path)
      // }
    }
  }
}
</script>
<style>
img{
  width: 60px;
  height: 60px;
}
.homeCard{
  text-align: center;
}
.card{
  width: 12vw;
  height: 16.8vh;
  margin: 10px 32px 10px 0;
  min-width: 120px;
  cursor: pointer;
  position: relative;
}
.ant-card-body{
  text-align: center;
  padding: 0px;
  transform: translate(-50%, -50%);
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
}
.content{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-left: 2px;
  padding-right: 2px;
}
.main{
  display: flex;
  float: left;
  flex-wrap: wrap;
}
</style>